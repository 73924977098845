document.addEventListener('DOMContentLoaded', () => {
    calculateSizerHeight();
});

window.addEventListener('resize', () => {
    calculateSizerHeight();
});

function calculateSizerHeight() {
    const referenceWrapper = document.getElementById('reference-wrapper');
    const backgroundShape = document.getElementById('background-shape');

    if (referenceWrapper) {
        setHeightOfSizer(referenceWrapper.offsetHeight - (backgroundShape.offsetHeight || 0));
    }
}

function setHeightOfSizer(height) {
    const sizer = document.querySelector('#references .sizer');

    sizer.style.minHeight = `${height}px`;
}
